
import { defineComponent, onMounted, computed, watch, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";

export default defineComponent({
  name: "user-account-overview",
  props: ["accountid", "clientuser"],
  components: { TableSkeleton },
  setup(props) {
    const store = useStore();
    const isDataReady = ref(false);
    /*const accountInfo = computed(() => {
      return store.getters.getAccountInfo(props.accountid);
    });*/

    const accountInfo = computed(() => {
      return store.getters.getSelectedApplicationUserProfile;
    });

    store
      .dispatch(Actions.GET_APPLICATION_USER_PROFILE, props.accountid)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText:
            "There was a problem retrieving the users profile.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    onMounted(() => {
      setCurrentPageTitle("User Profile");
      setCurrentPageBreadcrumbs("Overview", ["User"]);
    });

    watch(accountInfo, () => {
      isDataReady.value = true;
    });

    return {
      accountInfo,
      isDataReady,
    };
  },
});
